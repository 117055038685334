import React from 'react';


  const Contacts = () => {
    return (
  <>
<div id="rec273932866" class="r t-rec t-rec_pt_0" style={{ paddingTop: '0px' }} data-record-type="396">

            <div class="t396">
                <div class="t396__artboard" data-artboard-recid="273932866" data-artboard-screens="320,480,640,960,1200">
                    <div class="t396__carrier" data-artboard-recid="273932866"></div>
                    <div class="t396__filter" data-artboard-recid="273932866"></div>
                    <div class="t396__elem tn-elem tn-elem__2739328661611649501161" data-elem-id="1611649501161">
                        <div class="tn-atom" field="tn_text_1611649501161">МЕЖДУНАРОДНАЯ ПРЕМИЯ «ГЛОБАЛЬНЫЙ ПРОРЫВ»</div>
                    </div>
                    <div class="t396__elem tn-elem tn-elem__2739328661659352971013" data-elem-id="1659352971013">
                        <div class="tn-atom" field="tn_text_1659352971013">Общероссийская Общественная Организация «Деловая Россия» </div>
                    </div>
                    <div class="t396__elem tn-elem  tn-elem__2739328661659352971013" data-elem-id="1659352971113">
                        <div class="t562__text t-text t-text_md" style={{ color: '#fbf8f5' }} field="tn_text_1659352971013">127473, Москва, ул. Делегатская, д. 8 стр.3
                        </div>
                    </div>
                    <div class="t396__elem tn-elem tn-elem__2739328661659352971013" data-elem-id="1659352974513">
                        <div class="t562__text t-text t-text_md" style={{ color: '#fbf8f5' }} field="tn_text_1659352971013">тел.: (495) 6491828
                        </div>
                    </div>
                    <div class="t396__elem tn-elem tn-elem__2739328661659352971013" data-elem-id="16593529489513">
                        <div class="t562__text t-text t-text_md" style={{ color: '#fbf8f5' }} field="tn_text_1659352971013">Е-mail: info@deloros.com
                        </div>
                    </div>
                    <div class="t396__elem tn-elem tn-elem__2739328661611649583847" data-elem-id="1611649583847">
                        <div class="tn-atom" field="tn_text_1611649583847">КОНТАКТЫ</div>
                    </div>
                    <div class="t396__elem tn-elem tn-elem__2739328661611649933808 t-animate">
                        <div class="tn-atom"> </div>
                    </div>
                    <div class="t396__elem tn-elem tn-elem__2739328661580382659338" data-elem-id="1580382659338">
                        <div class="tn-atom"> <img class="tn-atom__img t-img"
                        src='https://optim.tildacdn.com/tild3663-3461-4364-b130-643463323561/-/format/webp/istockphoto_11667743.png'
                                data-original="https://static.tildacdn.com/tild3663-3461-4364-b130-643463323561/istockphoto_11667743.png"
                                alt="" imgfield="tn_img_1580382659338"/> </div>
                    </div>
                </div>
            </div>

        </div>
  </>

 
);
};
  
  export default Contacts;
  