import Articles1 from '../images/article/article_1.webp';
import Articles2 from '../images/article/article_2.webp';
import Articles3 from '../images/article/article_3.webp';
import Articles4 from '../images/article/article_4.webp';
import Articles5 from '../images/article/article_5.webp';
import Articles6 from '../images/article/article_6.webp';
import Articles7 from '../images/article/article_7.webp';
import Articles8 from '../images/article/article_8.webp';
import Articles9 from '../images/article/article_9.webp';
import Articles10 from '../images/article/article_10.webp';
import Articles11 from '../images/article/article_11.webp';
import Articles12 from '../images/article/article_12.webp';

const articlesData = [
    {
      id: 1,
      title: '3 причины, почему подписчики отписываются — разбор ошибок',
      text: 'В Москве прошел круглый стол «Как повысить эффективность мероприятий по охране труда?», организаторами которого выступили экспертный центр по ESG-трансформации и экспертный центр по трудовым отношениям и охране труда «Деловой России».',
      image: Articles1,
      date: '12 сентября 2024',
      autor: 'Александр Стоянцев',
      content: [
        { type: 'paragraph', text: 'В Москве прошел круглый стол «Как повысить эффективность мероприятий по охране труда?», организаторами которого выступили экспертный центр по ESG-трансформации и экспертный центр по трудовым отношениям и охране труда «Деловой России».' },
        { type: 'paragraph', text: 'Участники обсудили, с какими вызовами сталкивается бизнес, как на него влияет охрана труда и какие инструменты используются в российских компаниях для повышения производственной безопасности. С приветственным словом выступил член генсовета, руководитель экспертного центра по ESG-трансформации Николай Кривозерцев и член генсовета, руководитель экспертного центра по трудовым отношениям и охране труда «Деловой России» Виталий Федин.' },
        { type: 'paragraph', text: '«Уровень безработицы, с которой еще не сталкивалась наша страна, влияет на кадровый голод. Давление санкций, высокие ставки кредитования, логистические проблемы делают вопрос эффективности бизнеса вопросом выживания. Охрана труда – это инструмент эффективности всего предприятия в целом, именно так ее и нужно воспринимать», – отметил Николай Кривозерцев.' },
        { type: 'paragraph', text: 'По данным Росстата, в стране больше 70% предприятий испытывают нехватку кадров, учитывая, что уровень безработицы составляет почти 2,5%, а показатель загрузки производственных мощностей превысил 80%.' },
        { type: 'paragraph', text: 'Участники встречи рассказали об экономии средств предприятия и развитии культуры безопасности, методах повышения интереса к охране труда и мотивации исполнения требований производственной безопасности, решениях по автоматизации охраны труда, электронного документооборота и обучения сотрудников компаний.' },
        { type: 'paragraph', text: 'По итогам встречи эксперты предложили провести опрос среди лидеров безопасности, чтобы понять, какими качествами должен обладать руководитель по охране труда. Ожидается, что такое исследование может пройти в 2025 году. После участники мероприятия задали вопросы спикерам: один из них касался того, как добиться финансирования новых решений от руководства. Эксперты сошлись во мнении, что важно провести подробные расчеты по времени и расходам, показать выгоду предприятия при внедрении новых инструментов, а также опираться на ценности и убеждения руководителя.' },

        
    ]
    },
    {
      id: 2,
      title: 'Продукт компании якутского делоросса вошел в топ-30 международного акселератора Sber500',
      text: 'На демо-дне акселерационной программы Sber500 x GigaChat в Москве прошла презентация финалистов акселератора Сбера. Avareange - продукт компании «Аватек», соучредителем которой является сопредседатель якутского отделения «Деловой России» Антон Ломакин, вошел в топ-30 лучших AI-проектов.',
      image: Articles2,
      date: '20 марта 2024',
      autor: 'Анастасия Мезенцева',
      content: [
        { type: 'paragraph', text: 'На демо-дне акселерационной программы Sber500 x GigaChat в Москве прошла презентация финалистов акселератора Сбера. Avareange - продукт компании «Аватек», соучредителем которой является сопредседатель якутского отделения «Деловой России» Антон Ломакин, вошел в топ-30 лучших AI-проектов.' },
        { type: 'paragraph', text: 'Цель платформы - защита корпоративных данных и снижение кибер-рисков. Команда на демо-дне продемонстрировала усиление анализа угроз и повышение точности их обнаружения при интеграции Avareange с ИИ GigaChat от Сбера.' },
        { type: 'paragraph', text: 'По данным Сбербанка, к концу этого года общая сумма украденных у российских граждан средств может достичь около 250 млрд руб. Avareange станет надежным инструментом для компаний, обеспечивая их защиту в реальном времени.' },
        { type: 'paragraph', text: '«Мы уверены, что кибербезопасность должна быть доступной каждому, независимо от масштаба бизнеса. Программа Sber500 открывает перед нами новые горизонты для распространения в России и выхода на международный рынок, где мы сможем предоставить нашим клиентам решения для эффективной защиты их данных», - отметил Антон Ломакин.' },


        
    ]
    },
    {
      id: 3,
      title: 'Делороссы Кабардино-Балкарии провели дискуссию по вопросам цифровизации бизнеса',
      text: 'Основы цифровой безопасности, влияние цифровых технологий на трансформацию бизнес-моделей, IT-кадры и цифровые сервисы для бизнеса обсудили на круглом столе, организованном Кабардино-Балкарским региональным отделением «Деловой России» при участии Министерства цифрового развития республики.',
      image: Articles3,
      date: '19 января 2024',
      autor: 'Николай Васильев',
      content: [
        { type: 'paragraph', text: 'Основы цифровой безопасности, влияние цифровых технологий на трансформацию бизнес-моделей, IT-кадры и цифровые сервисы для бизнеса обсудили на круглом столе, организованном Кабардино-Балкарским региональным отделением «Деловой России» при участии Министерства цифрового развития республики. Мероприятие прошло 30 января и собрало порядка 30 заинтересованных предпринимателей.' },
        { type: 'paragraph', text: 'Министр цифрового развития Кабардино-Балкарской Республики (КБР) Аскер Бозиев рассказал об основах цифровой безопасности для бизнеса, обозначил базовые принципы работы с персональными данными и кибергигиены в целом. Спикер отметил, что в первом полугодии 2024 года по сравнению с аналогичным периодом прошлого года отмечен значительный рост зарегистрированных киберпреступлений, большая часть из которых использует принципы социальной инженерии. Поддавшиеся искушению люди не успевают оценить опасность ситуации и сами сообщают злоумышленникам чувствительную информацию. Риски реализации сценария с использованием социальной инженерии можно существенно сократить, но невозможно нивелировать полностью. Никакие тренинги и программные средства защиты не дают гарантии, что сотрудник не выполнит целевое для хакера действие. Поэтому министр отметил важность постоянной разъяснительной работы среди сотрудников компаний.' },
        { type: 'paragraph', text: 'Директор колледжа IT-хаб в Нальчике Ибрагим Созаев рассказал о подготовке IT-кадров, в том числе для бизнеса. Спикер обозначил несколько проблем в обучении специалистов и пути их решения, применяемые в колледже. В их числе, адаптивность образовательного процесса постоянным новшествам в отрасли, фокус на практическое применение знаний с партнерстве с крупными IT-компаниями региона, появление новых востребованных направлений обучения, важность постоянного самообразования выпускников.' },
        { type: 'paragraph', text: 'Сооснователь IT-сообщества «ИТ КБР» Мурат Пиакартов поделился примерами из личного практического опыта по автоматизации и цифровизации бизнес-процессов в различных компаниях, а также отметил основные риски, которые они с собой несут, особенно в тех случаях, когда процесс «перевода компании на новые рельсы» выполнен не в полном объеме или не уделено достаточное внимание подготовке персонала работе в новых условиях.' },
        { type: 'paragraph', text: 'Маркетолог и председатель Ассоциации рестораторов, отельеров и мастеров индустрии гостеприимства Северо-Кавказского ФО, делоросс Индира Гузеева презентовала участникам цифровой сервис для автоматизации малого и среднего бизнеса UDS. Спикер представила основной функционал, уделив особое внимание таким эффективным маркетинговым инструментам, как рекламные рассылки и пуш-уведомления, соответствующие действующему законодательству, отзывы и обратная связь, программа лояльности и другие.' },
        { type: 'paragraph', text: 'Спикеры ответили на вопросы участников, которые, в свою очередь, внесли предложения по совместным усилиям в профилактической борьбе с кибермошенничеством, стороны обменялись контактами для более предметного взаимодействия по вопросам цифровизации и автоматизации бизнеса.' },


        
    ]
    },
    {
      id: 4,
      title: 'Самарские делороссы выстраивают взаимодействие с коллегами из Казахстана',
      text: '«Казахстан — это страна с огромным потенциалом, и мы уверены, что совместные усилия могут привести к значительным результатам. Мы видим значительный экспортный потенциал, который может быть реализован при активном взаимодействии наших компаний», – отметил председатель Самарского регионального отделения «Деловой России» Вадим Некрасов, приветствуя делегацию Республики Казахстан.',
      image: Articles4,
      date: '06 декабря 2023',
      autor: 'Анастасия Мезенцева',
      content: [
        { type: 'paragraph', text: '«Казахстан — это страна с огромным потенциалом, и мы уверены, что совместные усилия могут привести к значительным результатам. Мы видим значительный экспортный потенциал, который может быть реализован при активном взаимодействии наших компаний», – отметил председатель Самарского регионального отделения «Деловой России» Вадим Некрасов, приветствуя делегацию Республики Казахстан.' },
        { type: 'paragraph', text: '26 по 27 декабря зарубежные коллеги совершили бизнес-миссию в Самарскую область. Визит был организован региональным «Клубом экспортеров» по заказу Министерства экономического развития и инвестиций Самарской области в рамках нацпроекта «Международная кооперация и экспорт» с целью продвижения экспортеров Самарской области.' },
        { type: 'paragraph', text: '«Самарские компании всегда были и остаются открытыми для сотрудничества и готовы оказать помощь в реализации совместных проектов. Мы понимаем, что успешное партнерство требует не только взаимовыгодных условий, но и глубокого уважения к культуре, традициям и бизнес-практикам друг друга. Наши отношения с Казахстаном строятся на прочной основе взаимного уважения и стремления к взаимовыгодному развитию. Я убежден, что совместная работа откроет новые горизонты для наших экономик и укрепит наши партнерские связи. Взаимодействие между нашими регионами может создать синергию, которая позволит нам более эффективно использовать ресурсы и реализовывать инновационные проекты», – приветствовал зарубежных коллег Вадим Некрасов.' },
        { type: 'paragraph', text: 'Экспортно ориентированные предприятия Самарской области провели активные b2b переговоры в конференц-зале в рамках реверсной бизнес-миссии, а также пригласили делегацию из Республики Казахстан на свои предприятия, где познакомили гостей с производственным процессом. В мероприятии приняли участие более 12 региональных компаний, специализирующихся на пищевой промышленности, машиностроении, строительных материалах и производстве электрического оборудования.' },
        { type: 'paragraph', text: 'Самарские компании провели ряд целевых встреч с потенциальными клиентами, по итогам которых компании высоко оценивают вероятность заключения экспортных контрактов. Реверсная бизнес-миссия открыла новые перспективы для развития взаимовыгодного сотрудничества между предприятиями Самарского региона и Республики Казахстан.' },
        { type: 'paragraph', text: 'Представители делегации из Республики Казахстан выразили глубокую благодарность за проведение мероприятия и заинтересованность в расширении и укреплении деловых взаимосвязей с Самарским регионом. Они подчеркнули свою готовность оказывать поддержку в организации совместных мероприятий, направленных на развитие двустороннего сотрудничества.' },
        
    ]
    },
    {
      id: 5,
      title: 'Делороссы представили IT-решения для расширения сотрудничества стран БРИКС',
      text: 'На международном муниципальном форуме БРИКС делороссы презентовали IT-решения для расширения сотрудничества по технологическому, экономическому и социальному развитию муниципалитетов. Инициативы представили члены генсовета «Деловой России» Алексей Мостовщиков, Роман Бокерия и Вадим Юн, а также член татарстанского отделения организации Айрат Давлетшин.',
      image: Articles5,
      date: '17 октября 2023',
      autor: 'Эдуард Миронов',
      content: [
        { type: 'paragraph', text: 'На международном муниципальном форуме БРИКС делороссы презентовали IT-решения для расширения сотрудничества по технологическому, экономическому и социальному развитию муниципалитетов. Инициативы представили члены генсовета «Деловой России» Алексей Мостовщиков, Роман Бокерия и Вадим Юн, а также член татарстанского отделения организации Айрат Давлетшин.' },
        { type: 'paragraph', text: 'Роман Бокерия рассказал о технологической платформе по покупке, продаже, оценке бизнеса и сопровождению сделок Bizavi. «Сейчас начинаем тестировать работу платформы в рамках БРИКС. Идея в том, чтобы собрать на ней российские, индийские, китайские, бразильские, южноафриканские проекты на продажу. Это обеспечит прозрачность коммуникации между покупателями и инвесторами, как частными, так и государственными», – заявил делоросс.' },
        { type: 'paragraph', text: 'Вадим Юн презентовал проекты для управления ESG-повесткой компаний, например, платформу для сбора данных о работе промышленных площадок, решения для мониторинга состояния городской среды и региона с анализом экологической обстановки и оценкой вероятности катастроф, блокчейн для биржи углеродных кредитов.' },
        { type: 'paragraph', text: 'Айрат Давлетшин и Алексей Мостовщиков предложили IT-решения для подготовки кадров для новой экономики. Последний считает необходимым продолжить внедрение ИИ, а также уделить больше внимания индивидуальному подходу. «Адаптивное обучение может улучшить образовательный процесс. Системы могут адаптировать учебный контент и задания в реальном времени, основываясь на уровнях знаний и предпочтениях каждого ученика. Платформы могут предоставлять дополнительные ресурсы или задания, которые соответствуют индивидуальным потребностям», – сказал делоросс.' },
        { type: 'paragraph', text: '«Онлайн-образование - хороший способ повышать квалификацию без потери в качестве выполнения обязанностей и без отрыва от производства», – добавил Айрат Давлетшин, отметив возможности дистанционных платформ для переобучения специалистов и повышения квалификации кадрового состава.' },
        { type: 'paragraph', text: '«Для успешной цифровой трансформации образования города и регионы могут интегрировать свои образовательные платформы и популяризировать их, создавать международные маркетплейсы образовательных услуг. Обмен лучшими практиками и опытом, совместное финансирование и реализация образовательных проектов, таких как цифровые лаборатории и онлайн-курсы, могут ускорить процесс. Также важно разработать единые стандарты и протоколы для интеграции технологий в учебный процесс. Установление партнерств с технологическими компаниями и университетами может обеспечить доступ к новейшим разработкам и ресурсам», – сообщил Алексей Мостовщиков.' },

        
    ]
    },
    {
      id: 6,
      title: 'Делоросс обозначил преимущества франчайзинга для развития турпроектов',
      text: 'Франчайзинг в туризме предлагает множество преимуществ для инвесторов и предпринимателей, начиная от готовых бизнес-моделей до облегченного доступа к финансовым ресурсам. Это делает его эффективным инструментом для распространения и развития турпроектов. Об этом заявил член генсовета «Деловой России», координатор организации в УФО Леонид Гункевич. Он выступил 27 августа на сессии «Высокие стандарты международного сотрудничества. Франчайзинг – технология территориального развития» во время Международного муниципального форума БРИКС.',
      image: Articles6,
      date: '29 июля 2023',
      autor: 'Александр Стоянцев',
      content: [
        { type: 'paragraph', text: 'Франчайзинг в туризме предлагает множество преимуществ для инвесторов и предпринимателей, начиная от готовых бизнес-моделей до облегченного доступа к финансовым ресурсам. Это делает его эффективным инструментом для распространения и развития турпроектов. Об этом заявил член генсовета «Деловой России», координатор организации в УФО Леонид Гункевич. Он выступил 27 августа на сессии «Высокие стандарты международного сотрудничества. Франчайзинг – технология территориального развития» во время Международного муниципального форума БРИКС.' },
        { type: 'paragraph', text: 'Среди ключевых направлений в развитии туристической привлекательности территорий, где предприниматели могут организовать бизнес по модели франчайзинга, делоросс отметил следующие отрасли:' },
        { type: 'list', text: 'Обустройство пляжей, детских и спортивных зон отдыха' },
        { type: 'list', text: 'Создание круглогодичных парков развлечений' },
        { type: 'list', text: 'Создание инфраструктуры горнолыжных курортов' },
        { type: 'list', text: 'Создание многофункциональных комплексов для транспортной доступности туристических локаций' },
        { type: 'list', text: 'Создание модульных кемпингов, автокемпингов и глемпингов' },
        { type: 'paragraph', text: 'По словам Леонида Гункевича, в этом году спрос на покупку готовой бизнес-идеи для отелей, гостиниц и баз отдыха вырос на 53 % по сравнению с прошлым годом. Увеличение турпотока влияет на развитие франчайзинга в общепите – более чем на 20%, а также в индустрии welness – больше, чем на 10%. Спрос на ресторанные франшизы в стране также вырос почти на 60%.' },
        { type: 'paragraph', text: '«Происходит перераспределение турпотоков, расширяется география на внутренних рынках и синергия брендов и предпринимателей на местах дает мощный экономический эффект. В ближайшем будущем рынок франчайзинга может развиваться и за счет малых городов, различных программ поддержки и привлечения в отрасль молодых предпринимателей», – считает спикер.' },
        { type: 'paragraph', text: 'Делоросс подчеркнул, что франшиза предоставляет бизнесу стандарты качества и обучение персонала выдерживать высокий уровень сервиса, а как следствие – удерживать клиентов и привлекать новых. Также конкурентоспособность увеличивается за счет узнаваемого бренда, который гарантирует стабильное качество услуг.' },
        { type: 'paragraph', text: 'Вместе с тем, франчайзинг позволяет быстро расширить географическую сеть без необходимости разрабатывать новые концепции с нуля. Кроме того, франчайзинговая модель предлагает готовые маркетинговые стратегии и операционные процедуры, что ускоряет процесс открытия новых точек. В числе примеров успешного внедрения франчайзинга Леонид Гункевич привел туркластер Большая Сысерть и различные гастрофестивали уральской кухни.' },
        
    ]
    },
    {
      id: 7,
      title: '«Деловая Россия» и «Центр защиты бизнеса» договорились о сотрудничестве',
      text: '«Деловая Россия» и АНО «Центр защиты» бизнеса заключили соглашение о сотрудничестве. Документ подписали президент бизнес-объединения Павел Титов и директор некоммерческой организации Андрей Ионченков.',
      image: Articles7,
      date: '16 мая 2023',
      autor: 'Николай Васильев',
      content: [
        { type: 'paragraph', text: '«Деловая Россия» и АНО «Центр защиты» бизнеса заключили соглашение о сотрудничестве. Документ подписали президент бизнес-объединения Павел Титов и директор некоммерческой организации Андрей Ионченков.' },
        { type: 'paragraph', text: 'Стороны договорились оказывать друг другу информационную поддержку, проводить мероприятия, конференции, семинары, выставки, презентации и совещания при реализации совместных проектов в сфере правовой защиты бизнеса. Также «Деловая Россия» и «Центр защиты бизнеса» будут обмениваться опытом, знаниями, технологическими и методологическими данными.' },
        { type: 'paragraph', text: '«Центр защиты бизнеса» специализируется на защите прав и интересов предпринимателей. Центр предоставляет правовую экспертизу и публичную поддержку в случаях немотивированного уголовного преследования, рейдерства, удержания под стражей без оснований и других конфликтов. Центр участвует в разработке предложений по совершенствованию российского законодательства с учетом мнения предпринимателей. Также организация содействует развитию позитивного образа российского бизнесмена и формированию благоприятного делового климата в стране.' },

        
    ]
    },
    {
      id: 8,
      title: 'Дмитрий Антонов предложил создать единый реестр благонадежных поставщиков России и Китая',
      text: 'Открывая форум, посол КНР в России Чжан Ханьхуэй отметил, что сельское хозяйство является одной из важнейших сфер практического сотрудничества между двумя странами. По его словам, китайские компании активно инвестируют в российскую аграрную отрасль, а общий объем инвестиций по 255 ключевым проектам достиг 11,6 млрд долл. США.',
      image: Articles8,
      date: '23 февраля 2023',
      autor: 'Эдуард Миронов',
      content: [
        { type: 'paragraph', text: 'Член генерального совета и председатель комитета «Деловой России» по АПК Дмитрий Антонов принял участие в Российско-Китайском форуме по сельскохозяйственному сотрудничеству. Его организаторами выступили посольство КНР в РФ и Союз китайских предпринимателей в РФ в сотрудничестве с Министерством сельского хозяйства РФ. Участники мероприятия, среди которых – представители профильных ведомств, предприниматели и эксперты двух стран, обсудили текущее состояние и перспективы развития сотрудничества в области сельского хозяйства и агропромышленного комплекса.' },
        { type: 'paragraph', text: 'Открывая форум, посол КНР в России Чжан Ханьхуэй отметил, что сельское хозяйство является одной из важнейших сфер практического сотрудничества между двумя странами. По его словам, китайские компании активно инвестируют в российскую аграрную отрасль, а общий объем инвестиций по 255 ключевым проектам достиг 11,6 млрд долл. США.' },
        { type: 'paragraph', text: 'Дмитрий Антонов в своем выступлении затронул такие направления сотрудничества между КНР и РФ как производство и экспорт готовой мясной продукции, банковский комплаенс, а также обратный реинжиниринг оборудования для пищевой промышленности. «С точки зрения упрощения процедур банковского комплаенса, эффективным решением, на наш взгляд, могло бы стать создание единого реестра благонадежных поставщиков России и Китая, чтобы уполномоченные банки могли использовать эту информацию при проведении процедуры проверки контрагента», – подчеркнул делоросс.' },
        { type: 'paragraph', text: 'Среди спикеров со стороны профильных ведомств выступили представители Министерства сельского хозяйства и сельских дел КНР, Комитета Совета Федерации по аграрно-продовольственной политике и природопользованию, Министерства сельского хозяйства России, Российского экспортного центра и другие.' },
        { type: 'paragraph', text: 'Китайско-российский форум по сельскохозяйственному сотрудничеству стал площадкой для обсуждения перспектив и вызовов в сфере сельскохозяйственного сотрудничества между двумя странами, а также для поиска новых возможностей для укрепления и расширения деловых связей в этом секторе.' },
        
    ]
    },
    {
      id: 9,
      title: 'Компания делоросса перезапустила завод «Севкабель»',
      text: '«Акрон Холдинг» перезапустил мощности петербургского завода «Севкабель» на производственной площадке «СКТ Групп» в Пскове. Предприятие займется выпуском разной кабельной продукции, сварочных комплексов для атомной отрасли, полимерных материалов для кабельной промышленности.',
      image: Articles9,
      date: '06 декабря 2023',
      autor: 'Василий Антонов',
      content: [
        { type: 'paragraph', text: '«Акрон Холдинг» перезапустил мощности петербургского завода «Севкабель» на производственной площадке «СКТ Групп» в Пскове. Предприятие займется выпуском разной кабельной продукции, сварочных комплексов для атомной отрасли, полимерных материалов для кабельной промышленности.' },
        { type: 'paragraph', text: '«Для нас это стало одним из самых масштабных проектов – фактически мы перевезли завод из Санкт-Петербурга в Псков, в сердце кабельной промышленности России. Это беспрецедентный проект в новейшем времени не только в масштабах холдинга, но и всей страны: более 60 линий было приобретено и перевезено нами в Псковскую область. Мы гордимся, что в свое время сумели сохранить лидера индустрии, являющегося первым кабельным заводом России, с которого началась история развития отрасли. Сегодня он переживает второе рождение, и я уверен, что у нас впереди множество интересных задач и перспективных проектов», - заявил член корсовета «Деловой России» и совета директоров «Акрон Холдинг» Александр Кобенко.' },
        { type: 'paragraph', text: '«Я надеюсь, что новая площадка легендарного завода «Севкабель» займет достойное место на рынке кабельной промышленности, будет развивать современные технологии, внедрять решения мирового уровня, локализовывать производство критичных компонентов на территории России», — говорится в поздравлении главы Минпромторга России Антона Алиханова.' },
        { type: 'paragraph', text: 'Общий объем инвестиций в проект составляет 5 млрд руб., а поддержка от государства в размере 268 млн руб. позволит расширить ассортимент продукции. Объем внутреннего производства в кабельной отрасли в 2023 году составил более 400 млрд руб., что на 21% больше, чем в 2022 году.' },
        { type: 'paragraph', text: 'Во время открытия специалисты завода продемонстрировали образцы продукции, включая медную и алюминиевую катанку, а также экспонаты из музейного фонда, например, «кабель жизни» из блокадного Ленинграда.' },
        
    ]
    },
    {
      id: 10,
      title: 'Делороссы предлагают распространить инструменты РИС на девелоперские проекты',
      text: 'Комитет «Деловой России» по строительству выступил с инициативой распространить инструменты регионального инвестиционного стандарта (РИС) на проекты строительства жилья. Это предложение и его обоснование были представлены 14 октября на заседании Комиссии по вопросам сопровождения инвестиционных проектов Общественного совета при Минстрое РФ.',
      image: Articles10,
      date: '14 октября 2022',
      autor: 'Александр Стоянцев',
      content: [
        { type: 'paragraph', text: 'Комитет «Деловой России» по строительству выступил с инициативой распространить инструменты регионального инвестиционного стандарта (РИС) на проекты строительства жилья. Это предложение и его обоснование были представлены 14 октября на заседании Комиссии по вопросам сопровождения инвестиционных проектов Общественного совета при Минстрое РФ.' },
        { type: 'paragraph', text: 'Сопредседатели комитета «Деловой России» по строительству Алексей Долматов и Михаил Викторов входят в состав Общественного совета при Минстрое РФ. Ключевой темой заседания комиссии стала выработка законодательных инициатив по необходимым мерам поддержки отрасли жилищного строительства в стране.' },
        { type: 'paragraph', text: 'Во многих регионах проекты строительства жилья – многоквартирных домов и апартаментов, индивидуальное жилищное строительство (ИЖС), а также проекты комплексного развития территорий не входят в сферу компетенций ответственных за инвестиции РОИВов, и в большинстве своем не сопровождаются агентствами инвестиционного развития и инструментами поддержки новых инвестиционных проектов. Проведенный «Деловой Россией» в сентябре 2023 года в 50 регионах опрос показал, что в 39 регионах строительные проекты не находятся в орбите инструментов РИС. Вместе с этим, объемы инвестиций в этом секторе одни из самых высоких в стране, аргументирует свою позицию комитет. Распространение инструментов РИС на девелоперские проекты эксперты предлагают закрепить на уровне федерального законодательства и нормативного регулирования.' },
        { type: 'paragraph', text: 'Предложение коллег из комитета поддержал член Генсовета и координатор «Деловой России» по Северо-Западному ФО Дмитрий Панов. В период проведения региональной инвестгруппой мониторинга внедрения РИС в Санкт-Петербурге представители целого ряда девелоперских компаний выражали свое удивление тому факту, что их проекты не учитываются в контуре регионального инвестиционного ландшафта и таким образом не влияют на результаты региона в сфере реализации элементов Регинвестстандарта, сказал он.' },
        { type: 'paragraph', text: 'При этом, отметил Дмитрий Панов, данный вопрос приобретает особенную значимость именно в текущий момент времени, когда большинство массовых программ льготной ипотеки прекратили свое действие, а в стране продолжаются реализация национального проекта «Жилье и городская среда» по улучшению жилищных условий не менее 5 млн российских семей ежегодно и исполнение «майского» Указа Президента РФ по достижению национальной цели «Комфортная и безопасная среда для жизни».' },
        { type: 'paragraph', text: 'В рамках своих докладов эксперты подчеркнули важность включения жилищных проектов в орбиту Регинвестстандарта по следующим очевидным причинам:  ' },
        { type: 'list', text: 'высокий мультипликативный эффект отрасли – на каждый рубль вложенных в строительство средств приходится до 7 рублей инвестиций по широкому спектру смежных отраслей; ' },
        { type: 'list', text: 'высокий уровень кадровой обеспеченности – сегодня в сфере жилищного строительства занято порядка 6,5 млн человек;' },
        { type: 'list', text: 'значительный социально-экономический эффект для развития регионов, особенно в части реализации проектов комплексного освоения территорий, характеризующихся масштабным строительством объектов инженерной, транспортной и социальной инфраструктуры, созданием комфортной и благоустроенной городской среды.' },
        { type: 'paragraph', text: 'По мнению экспертов, включение представителей отрасли жилищного строительства в процессы внедрения Регионального инвестиционного стандарта позволит обеспечить дополнительную качественную выработку новых инициатив и мер поддержки, направленных на улучшение инвестиционного климата во всех без исключения регионах нашей страны.' },
        
    ]
    },
    {
      id: 11,
      title: 'Эксперты продолжают диалог с властью по предоставлению налоговых льгот водоканалам',
      text: 'Комитет «Деловой России» по коммунальной инфраструктуре и ЖКХ и Российская ассоциация водоснабжения и водоотведения (РАВВ) подготовили развёрнутое обоснование необходимости введения налоговых льгот водоканалам на вновь построенные и реконструированные объекты в сфере водоснабжения и водоотведения. Соответствующее письмо было направлено в Минстрой России.',
      image: Articles11,
      date: '08 августа 2022',
      autor: 'Николай Васильев',
      content: [
        { type: 'paragraph', text: 'Комитет «Деловой России» по коммунальной инфраструктуре и ЖКХ и Российская ассоциация водоснабжения и водоотведения (РАВВ) подготовили развёрнутое обоснование необходимости введения налоговых льгот водоканалам на вновь построенные и реконструированные объекты в сфере водоснабжения и водоотведения. Соответствующее письмо было направлено в Минстрой России.' },
        { type: 'paragraph', text: 'Целесообразность установления налоговых льгот для отрасли обоснована, с одной стороны, важностью поддержки экономики предприятий и привлечения внебюджетных инвестиций, с другой – возможностью не повышать тарифы для населения. Так, при отсутствии соответствующих льгот для водоканалов налоги на новые объекты инфраструктуры, которые должны быть учтены в тарифах, могут увеличить платежи граждан от 9,4% до 17,1% в зависимости от региона. При этом по оценкам РАВВ, доля дополнительных налогов от вновь построенных объектов в валовой выручке водоканалов может составлять до 24,7%, что негативно влияет на экономику предприятий.' },
        { type: 'paragraph', text: 'В письме Минстрою России предлагается также продлить право водоканалам применять к основной норме амортизации специальный коэффициент, но не выше 3 в отношении амортизируемых основных средств, используемых в сфере водоснабжения и водоотведения. Мера, которая была введена в 2018 году по инициативе РАВВ прекратила своё действие для отрасли 1 января 2023 года. За этот период она оказала существенную поддержку предприятиям водопроводно-канализационного хозяйства. Для примера, экономический эффект за период 2018-2022 годы составил: 1,2 млрд. руб. – АО «Мосводоканал», 534 млн. руб. – ООО «РКС-Холдинг», 106 млн. руб. – ООО «Росводоканал».' },
        { type: 'paragraph', text: 'Эксперты выражают надежду, что Минстрой России примет позицию отрасли и поддержит предложения РАВВ и «Деловой России».' },
        
    ]
    },
    {
      id: 12,
      title: '«Деловая Россия» запускает новый проект поддержки предпринимателей «Прозрачный ВЭД»',
      text: 'Повысить прозрачность бизнеса и упростить для предпринимателей прохождение таможенных процедур призван новый проект организации «Прозрачный ВЭД». Инициатором и руководителем проекта выступил член генерального совета «Деловой России», член Общественного совета при ФТС России Евгений Кошкаров.',
      image: Articles12,
      date: '17 апреля 2023',
      autor: 'Александр Стоянцев',
      content: [
        { type: 'paragraph', text: 'Повысить прозрачность бизнеса и упростить для предпринимателей прохождение таможенных процедур призван новый проект организации «Прозрачный ВЭД». Инициатором и руководителем проекта выступил член генерального совета «Деловой России», член Общественного совета при ФТС России Евгений Кошкаров.' },
        { type: 'paragraph', text: 'Он напомнил, что с момента своего возникновения в середине «нулевых» системы управления рисками (СУР) Федеральная таможенная служба (ФТС) опиралась на технологию категорирования участников внешнеэкономической деятельности (ВЭД): зеленый, желтый и красный коридор для бизнеса. Как было заявлено руководством ФТС, в перспективе 2024 года произойдет поэтапный демонтаж систему категорирования – ее должен заменить институт уполномоченных экономических операторов (УЭО).' },
        { type: 'paragraph', text: 'По замыслу руководства ФТС добросовестный внешнеэкономический бизнес должен подтвердить свои намерения войдя в реестр УЭО, пояснил делоросс. В ближайшем будущем все формы упрощения таможенного контроля будут в первую очередь предоставляться компаниям, имеющим такой статус. В настоящее время ФТС, Минфин и ЕЭК готовят нормативную базу расширяющую область использования статуса УЭО для упрощения таможенного контроля».' },
        { type: 'paragraph', text: '«Для своевременной реакции на анонсированные изменения и был инициирован наш проект. Он призван помочь членам «Деловой России» сориентироваться в изменяющемся в области таможенного регулирования правоприменении и помочь сделать правильный выбор в отношении получения статуса УЭО», – сообщил Евгений Кошкаров.' },
        
    ]
    },

    

];
  
export default articlesData;